<p-dialog
  [(visible)]="visible"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  id="headless_modal"
  styleClass="md:max-w-[600px] lg:max-w-[906px] w-full !bg-G50 mx-[16px] relative !rounded-[24px]"
>
  <div class="absolute top-[16px] md:top-[24px] right-[24px]">
    <x-button (click)="handleCancelClick($event)" />
  </div>
  <div
    class="flex justify-center px-[16px] pb-[64px] pt-[88px] md:pb-[100px] md:pt-[116px]"
  >
    <div class="max-w-[352px] flex flex-col justify-center items-center">
      <div
        [ngSwitch]="type"
        class="pb-[24px] max-w-[150px] sm:max-w-[200px] md:max-w-[300px] select-none"
      >
        <img
          *ngSwitchCase="'submit'"
          src="/assets/massimo/images/jobSubmit.png"
          alt="submit"
          class="w-full"
        />
        <img
          *ngSwitchCase="'failed'"
          src="/assets/massimo/images/jobFailed.png"
          alt="failed"
          class="w-full"
        />
        <img
          *ngSwitchCase="'success'"
          src="/assets/massimo/images/jobSuccess.png"
          alt="success"
          class="w-full"
        />
        <img
          *ngSwitchCase="'draft'"
          src="/assets/massimo/images/jobDraft.png"
          alt="draft"
        />
        <img
          *ngSwitchCase="'delete'"
          src="/assets/massimo/images/jobDelete.png"
          alt="delete"
        />
        <img
          *ngSwitchCase="'delete_portfolio'"
          src="/assets/massimo/images/jobDelete.png"
          alt="delete"
        />
        <img
          *ngSwitchCase="'accept'"
          src="/assets/massimo/images/jobAccept.png"
          alt="accept"
        />
        <img
          *ngSwitchCase="'decline'"
          src="/assets/massimo/images/jobDecline.png"
          alt="decline"
          class="max-w-[150px] md:max-w-[200px]"
        />
        <img
          *ngSwitchCase="'jobListingDeleted'"
          src="/assets/massimo/images/jobDeleted.png"
          alt="Job Listing Deleted"
        />
        <img
          *ngSwitchCase="'confirmTransaction'"
          src="/assets/massimo/images/confirmTransaction.png"
          alt="Confirm Transaction"
        />
        <div
          *ngSwitchDefault
          class="max-w-[322px] w-full h-[200px] bg-G400"
        ></div>
      </div>

      <div class="text-G900 text-h6 text-center font-medium pb-[8px]">
        {{ title }}
      </div>
      <div
        class="text-G700 text-b2 text-center font-normal pb-[20px] md:pb-[32px] whitespace-pre-line"
      >
        {{ content }}
      </div>

      <ng-container *ngIf="type === 'delete' || type === 'delete_portfolio' || type === 'withdraw'">
        <div class="flex gap-4 justify-center items-center">
          <div
            class="cursor-pointer py-[10px] px-[24px] flex justify-center items-center gap-2 hover:bg-G200 rounded-xl transition-all duration-200"
            (click)="LeftClick($event)"
          >
            <img
              src="/assets/massimo/svg/delete.svg"
              class="select-none w-[16px] h-[16px]"
            />
            <div
              class="text-R900 font-medium text-nowrap text-b1"
              *ngIf="type === 'delete'"
            >
              Delete Job
            </div>
            <div
              class="text-R900 font-medium text-nowrap text-b1"
              *ngIf="type === 'delete_portfolio'"
            >
              Delete
            </div>
            <div
              class="text-R900 font-medium text-nowrap text-b1"
              *ngIf="type === 'withdraw'"
            >
              Withdraw your Application
            </div>
          </div>
          <basic-button
            [type]="1"
            (click)="RightClick($event)"
            title="Cancel"
            size="small"
          />
        </div>
      </ng-container>

      <ng-container *ngIf="type === 'failed'">
        <div class="flex justify-center items-center">
          <basic-button
            [type]="2"
            (click)="handleCancelClick($event)"
            title="Retry"
            size="small"
          />
        </div>
      </ng-container>

      <ng-container *ngIf="type === 'submit'">
        <div class="flex flex-row gap-4 justify-center items-center">
          <basic-button
            [type]="1"
            [routerLink]="['/']"
            title="Go back home"
            size="small"
          />
          <basic-button
            [type]="2"
            [routerLink]="['/jobs']"
            title="See similar jobs"
            size="small"
            extraClass="border"
          />
        </div>
      </ng-container>

      <ng-container *ngIf="type === 'withdrawSuccess'">
        <div class="flex gap-4 justify-center items-center">
          <basic-button
            [type]="1"
            [routerLink]="['/']"
            title="Go back home"
            size="small"
          />
          <basic-button
            [type]="2"
            [routerLink]="['/jobs']"
            title="See another jobs"
            size="small"
            extraClass="border"
          />
        </div>
      </ng-container>

      <ng-container *ngIf="type === 'success'">
        <div
          class="flex flex-col-reverse sm:flex-row gap-4 justify-center items-center"
        >
          <div
            class="cursor-pointer py-[10px] px-[24px] text-G900 flex justify-center items-center gap-2 font-medium text-b1 text-nowrap hover:bg-G200 rounded-xl"
            (click)="LeftClick($event)"
          >
            <img
              src="/assets/massimo/svg/fi_share-2.svg"
              class="select-none w-[16px] h-[16px]"
            />
            Share Job Listing
          </div>
          <basic-button
            [type]="2"
            [routerLink]="['/jobs/'+jobId]"
            title="Invite Freelancers"
            size="small"
            extraClass="border"
          />
        </div>
      </ng-container>

      <ng-container *ngIf="type === 'draft'">
        <div class="flex gap-4 justify-center items-center">
          <basic-button
            [type]="1"
            [routerLink]="['/']"
            title="Go home"
            size="small"
          />
          <basic-button
            [type]="2"
            [routerLink]="['/inbox/jobs']"
            [queryParams]="{ tab: 3 }"
            title="See Drafts"
            size="small"
            extraClass="border"
          />
        </div>
      </ng-container>

      <ng-container *ngIf="type === 'jobListingDeleted'">
        <div class="flex gap-4 justify-center items-center">
          <basic-button
            [type]="1"
            [routerLink]="['/']"
            title="Return home"
            size="small"
          />
          <basic-button
            [type]="2"
            [routerLink]="['/inbox/post']"
            title="Create new job listing"
            size="small"
            extraClass="border"
          />
        </div>
      </ng-container>

      <ng-container *ngIf="type === 'accept'">
        <div class="flex gap-4 justify-center items-center">
          <div
            class="cursor-pointer py-[10px] px-[24px] hover:bg-G200 rounded-xl transition-all duration-200"
            (click)="LeftClick($event)"
          >
            <div class="text-R900 font-medium text-nowrap text-b1">Cancel</div>
          </div>
          <basic-button
            [type]="2"
            (click)="RightClick($event)"
            title="Accept"
            size="small"
          />
        </div>
      </ng-container>
      <ng-container *ngIf="type === 'decline'">
        <div class="flex gap-4 justify-center items-center">
          <div
            class="cursor-pointer py-[10px] px-[24px] hover:bg-G200 rounded-xl transition-all duration-200"
            (click)="LeftClick($event)"
          >
            <div class="text-R900 font-medium text-nowrap text-b1">Cancel</div>
          </div>
          <basic-button
            [type]="2"
            (click)="RightClick($event)"
            title="Decline"
            size="small"
          />
        </div>
      </ng-container>
      <ng-container *ngIf="type === 'confirmTransaction'">
        <div class="flex gap-4 justify-center items-center">
          <basic-button
            [type]="2"
            (click)="LeftClick($event)"
            title="Back To Job"
            size="small"
          />
          <div
            class="cursor-pointer py-[10px] px-[24px] hover:bg-G200 rounded-xl transition-all duration-200"
            (click)="RightClick($event)"
          >
            <div class="text-R900 font-medium text-nowrap text-b1">Cancel</div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</p-dialog>
