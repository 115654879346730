<app-invite-freelancers-dialog [(visible)]="showInviteFreelancerDialog" [job]="job" />

<section
  class="w-full rounded-[12px] rounded-tl-none sm:bg-white sm:border sm:border-G200 sm:px-[24px] sm:py-[32px] flex font-sans"
>
  <div *ngIf="bids.length === 0" class="py-[24px] flex w-full">
    <div class="flex justify-center items-center flex-col max-w-[380px] m-auto">
      <img src="/assets/massimo/images/folder.png" class="select-none w-[160px] sm:w-[240px] pb-[24px]" />

      <div class="text-G900 text-h6 font-medium">No proposal submitted yet.</div>
      <div class="text-G700 text-b2 font-normal text-center pb-[10px] md:pb-[32px]">
        You have no proposal submitted yet, consider inviting freelancers to your job listing and sharing on your social
        media.
      </div>

      <div class="flex gap-[16px] sm:gap-6 items-center max-md:flex-col">
        <div
          class="flex gap-2 text-G900 text-b1 font-semibold cursor-pointer text-nowrap hover:bg-G300 rounded-xl px-[20px] py-[10px] transition-all duration-300"
        >
          <img src="/assets/massimo/svg/fi_share-2.svg" class="select-none" />
          Share Job Listing
        </div>
        <basic-button title="Invite Freelancers" [type]="2" size="small" (click)="openInviteFreelancerDialog()" />
      </div>
    </div>
  </div>
  <div class="w-full" *ngIf="canSee && bids.length > 0">
    <div class="flex justify-between items-center pb-[16px] sm:pb-[24px]">
      <div class="text-G900 font-medium text-b1 sm:text-h6">Submissions</div>

      <div class="flex items-center gap-4 text-G900 text-b1">
        <div class="hidden sm:block font-[500] text-b2 whitespace-nowrap">Sort by</div>
        <p-dropdown
          #orderTypeSelect
          [options]="sortbylist"
          [(ngModel)]="selectedSortBy"
          optionLabel="name"
          appendTo="body"
        >
          <ng-template let-item pTemplate="item">
            <div class="flex items-center" (click)="SortbyFilter()">
              <span>{{ item.name }}</span>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>

    <p-table [value]="bids" [paginator]="bids.length > 10 ? true : false" [rows]="10" styleClass="min-h-[400px]">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th class="rounded-tl-xl">Name</th>
          <th>Bid</th>
          <th>Proposal</th>
          <th class="rounded-tr-xl">Rating</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-bid let-columns="columns">
        <tr class="hover:bg-G50 cursor-pointer" (click)="ShowDialogDetail(bid)">
          <td>
            <div class="flex gap-4">
              <div class="cursor-pointer" [routerLink]="['/profile/alt/', bid.providerId]">
                <app-avatar [size]="48" [user]="bid.providerInfo"></app-avatar>
              </div>
              <div>
                <div
                  class="line-clamp-1 text-b1 font-medium text-G900 cursor-pointer"
                  [routerLink]="['/profile/alt/', bid.providerId]"
                >
                  {{ bid.providerInfo.name }}
                  <verified-mark [user]="bid.providerInfo"></verified-mark>
                </div>
                <p class="line-clamp-1 text-b2 text-G500">
                  {{ bid.providerInfo.title }}
                </p>
              </div>
              <div class="flex items-center justify-center">
                <div class="bg-B600 text-white rounded-[45px] px-[10px] py-[2px]">New</div>
              </div>
            </div>
          </td>
          <td class="text-nowrap">${{ bid.budget }} <span *ngIf="job.paymentType !== 'Fixed price'">/hr</span> USD</td>
          <td>
            <div class="line-clamp-2 max-w-[300px]">
              {{ stripHtmlTags(bid.message) }}
            </div>
          </td>
          <td>
            <app-star-rating stars="5" />
          </td>
        </tr>
      </ng-template>
    </p-table>

    <proposal-details-dialog
      [(visible)]="visibleProposalDetails"
      [selectedBid]="selectedBid"
      (declinetBtnEvent)="updateDialogDeclineJob($event)"
      (accpetBtnEvent)="updateDialogAcceptJob($event)"
    />

    <!-- Modals start -->
    <basic-dialog
      [(visible)]="visibleAcceptModal"
      title="Do you want to accept this bid?"
      [content]="selectedBidContent"
      (LeftbtnEvent)="updateDialogAcceptJob($event)"
      (RightbtnEvent)="chooseProvider(selectedBid)"
      type="accept"
    />
    <basic-dialog
      [(visible)]="visibleDeclineModal"
      title="Do you want to decline this bid?"
      [content]="selectedBidContent"
      (LeftbtnEvent)="updateDialogDeclineJob($event)"
      (RightbtnEvent)="declineProvider(selectedBid)"
      type="decline"
    />
    <!-- Modals end -->
  </div>
</section>
